import React, { useEffect, useState, Fragment } from 'react';
import { helpers } from 'react-free-style/dist/react-free-style';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import { Spinner } from '@united-talent-agency/components';

import { colors, icons, sizes } from '@united-talent-agency/julius-frontend-components';
import { listHistoryProject, projectLoadRoles, getProjectCastings } from '@united-talent-agency/julius-frontend-store';

import History from '../../../../../components/history/history';
import DataTableLoader from '../../../../../components/content-loader/content-loader';

const Activity = ({ dispatch, projects, styles }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [data, setData] = useState(null);
  const [focused, setFocused] = useState({ focused: 0 });

  useEffect(() => {
    !projects?.project?._id
      ? notify.show('Unable to load content, Please try later!', 'custom', 2000, {
          background: '#000000',
          text: '#FFFFFF',
        })
      : fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const [rolesResponse, castingsResponse] = await Promise.all([
        dispatch(projectLoadRoles(projects.project._id)),
        dispatch(getProjectCastings(projects.project._id)),
        dispatch(listHistoryProject(projects.project._id)),
      ]);
      if (!rolesResponse?.body || !castingsResponse?.body) {
        throw new Error('Incomplete data received');
      }
      // Handle data
      setData({ roles: rolesResponse.body, castings: castingsResponse.body });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      notify.show('Error fetching data. Please try again later.', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setHistory(
      [
        ...(projects.history?.filter(
          e => e.delta && e.objectType !== 'project-trackings' && !('trackings' in e.delta)
        ) || []),
        ...(data?.roles?.map(role => changeAuspiceHistory(role.history, role._id)) || []),
        ...(data?.castings?.map(casting => changeCastingHistory(casting.history, casting._id)) || []),
      ]
        .flat()
        .sort((a, b) => {
          // Extract date objects
          const aDate = new Date(a.timestamp);
          const bDate = new Date(b.timestamp);

          // Compare date values
          if (isValidDate(aDate) && isValidDate(bDate)) {
            // Both are valid dates, sort by timestamp
            return b.timestamp - a.timestamp;
          } else if (isValidDate(aDate)) {
            // aDate is a valid date but bDate is not, move b to the bottom
            return -1;
          } else if (isValidDate(bDate)) {
            // bDate is a valid date but aDate is not, move a to the bottom
            return 1;
          } else {
            // Both are not valid dates, sort alphabetically by string value
            const aString = String(a.timestamp);
            const bString = String(b.timestamp);
            return aString.localeCompare(bString);
          }
        })
    );
  }, [projects.history, data]);

  // Function to check if a value is a valid date
  const isValidDate = value => {
    return value instanceof Date && !isNaN(value);
  };
  return (
    <div>
      <table id={'scriptTable'} className={isLoading ? 'd-flex flex-column w-100' : styles.table}>
        <thead>
          <tr className={styles.th}>
            <th className={isLoading ? 'pr-5' : styles.thText}>Date</th>
            <th className={isLoading ? 'px-5' : styles.thText}>Field Name</th>
            <th className={isLoading ? 'px-5' : styles.thText}>Previous Value</th>
            <th className={isLoading ? 'px-5' : styles.thText}>New Value</th>
            <th className={isLoading ? 'px-5' : styles.thText}>Action</th>
            <th className={isLoading ? 'px-5' : styles.thText}>User</th>
          </tr>
        </thead>
        <tbody>
          {!projects?.project?._id && history?.length === 0 ? (
            <Fragment>
              <tr className={styles.addContainer}>
                {Array.from(Array(6).keys()).map(item => {
                  return (
                    <td key={item} className="p-2">
                      <DataTableLoader />
                    </td>
                  );
                })}
              </tr>
            </Fragment>
          ) : (
            <Fragment>
              {isLoading ? (
                <div className={styles.spinnerContainer}>
                  <Spinner size={30} />
                </div>
              ) : (
                <RenderList history={history} setFocused={setFocused} focused={focused} />
              )}
            </Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
};

const RenderList = React.memo(function RenderList({ history }) {
  let showTable = false;

  if (history !== null && history.length > 0) showTable = true;
  return showTable ? (
    <Fragment>
      {history.map((loopItem, index) => {
        const item = {
          _id: index,
          delta: loopItem?.delta || { project: [[], [loopItem?.fieldId]] },
          user: {
            name:
              `${loopItem?.userId?.first_name ? loopItem?.userId?.first_name : ''} ${
                loopItem?.userId?.last_name ? loopItem?.userId?.last_name : ''
              }` || 'Oasis',

            _id: loopItem?.userId?._id || '',
          },
          timestamp: loopItem?.timestamp ? loopItem?.timestamp : undefined,
          recordDeleted: loopItem?.recordDeleted ? loopItem?.recordDeleted : false,
        };
        return <History history={item} index={index} key={index} />;
      })}
    </Fragment>
  ) : (
    <tr />
  );
});
export const changeAuspiceHistory = (history, _id) => {
  if (!Array.isArray(history)) return [];

  return history.map(entry => {
    if (!entry?.delta || typeof entry.delta !== 'object') {
      entry.delta = {
        created: [_id],
      };
    }

    const updatedEntry = { ...entry };

    // Update keys and handle special cases
    for (const [key, value] of Object.entries(updatedEntry.delta)) {
      if (key !== 'userId') {
        updatedEntry.delta[`Auspice: ${key}`] = value !== undefined ? value : '';
        delete updatedEntry.delta[key];
      }
    }

    if (Array.isArray(updatedEntry.delta['Auspice: agency'])) {
      updatedEntry.delta['Auspice: agency'] = updatedEntry.delta['Auspice: agency']
        .map(agencyObj => agencyObj?.name || '')
        .filter(Boolean); // Remove empty values
    }

    if (Array.isArray(updatedEntry.delta['Auspice: personId'])) {
      updatedEntry.delta['Auspice: personId'] = updatedEntry.delta['Auspice: personId']
        .map(personObj => personObj?.name || '')
        .filter(Boolean); // Remove empty values
    }

    for (const [key, value] of Object.entries(updatedEntry.delta)) {
      if (key.startsWith('Auspice:')) {
        if (
          key === 'Auspice: active' &&
          Array.isArray(value) &&
          value.length === 2 &&
          ((value[0] === true && value[1] === false) || (value[0] === false && value[1] === true))
        ) {
          for (const innerKey in updatedEntry.delta) {
            if (innerKey !== 'Auspice: active' && innerKey.startsWith('Auspice:')) {
              delete updatedEntry.delta[innerKey];
            }
          }
          break;
        }
      }
    }

    return updatedEntry;
  });
};

export const changeCastingHistory = (history, _id) => {
  if (!Array.isArray(history)) {
    return [];
  }

  return history
    .filter(entry => !entry.recordCreated)
    .map(entry => {
      try {
        if (!entry.delta) {
          entry.delta = {
            created: [_id],
          };
        }

        // Update keys and handle special cases
        for (const [key, value] of Object.entries(entry.delta)) {
          if (key !== 'userId') {
            entry.delta[`Casting: ${key}`] = value !== undefined ? value : '';
            delete entry.delta[key];
          }
        }

        // Handle special properties
        ['priority', 'ageMax', 'ageMin'].forEach(prop => {
          if (entry.delta[`Casting: ${prop}`]) {
            entry.delta[`Casting: ${prop}`] = entry.delta[`Casting: ${prop}`]
              .filter(agencyObj => agencyObj !== null)
              .map(agencyObj => agencyObj.toString());
          }
        });

        if (entry.delta['Casting: last_updated_date']) {
          delete entry.delta['Casting: last_updated_date'];
        }

        if (Array.isArray(entry.delta['Casting: representedByAgency'])) {
          entry.delta['Casting: representedByAgency'] = entry.delta['Casting: representedByAgency'].map(
            agencyObj => agencyObj?.name || ''
          );
        }

        if (entry.delta['Casting: personId']) {
          entry.delta['Casting: personId'] = Array.isArray(entry.delta['Casting: personId'])
            ? entry.delta['Casting: personId'].map(personObj => personObj?.name || '')
            : entry.delta['Casting: personId']?.name || '';
        }
      } catch (error) {
        console.error('Error processing entry:', error);
      }
      return entry;
    });
};
const withStyles = styled({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  addContainer: helpers.merge(sizes.container, {
    paddingTop: 25,
    background: 'white',
    border: '1px black solid',
  }),
  spinnerContainer: {
    position: 'absolute',
    left: '50vw',
    top: '50vh',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  starActiveIcon: icons.starActive,
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  loading: {
    textAlign: 'center',
    padding: 10,
  },
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
    },
    sizes.container
  ),
});

const withState = connect(state => {
  return state;
});

export default React.memo(withState(withStyles(Activity)));
